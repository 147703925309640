import React, { useState, useEffect } from 'react';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import CardListCompany from './components/card-list-company';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import './App.css';


// Function to format the date in the desired format (MM/DD/YYYY)
function formatDate(date) {
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  const year = date.getFullYear();
  return `${month}/${day}/${year}`;
}

function getFirstDayOfWeek(date) {
  const day = date.getDay() - 1 ; // 0 (Sunday) to 6 (Saturday)
  const firstDay = new Date(date.getTime() - day * 24 * 60 * 60 * 1000); // Subtract day offset
  return firstDay;
}


// Get today's date
const today = new Date();
{console.log((String(getFirstDayOfWeek(today).getDate()).padStart(2, '0')))}




function App() {
  // State variables to store fetched data, loading state, and error

  const [currentWeek, setCurrentWeek] = useState(() => {
    const firstDay = getFirstDayOfWeek(today);
    const formattedWeek = `${formatDate(firstDay)} - ${formatDate(new Date(firstDay.getTime() + 6 * 24 * 60 * 60 * 1000))}` // Add 6 days 

    return formattedWeek;
  });

  const handleClick = (direction) => {
    const adjustValue = direction === 'previous' ? -7 : 7;
    const [startDate, endDate] = currentWeek.split(' - ').map(dateString => new Date(dateString));

    const newStartDate = new Date(startDate.getTime() + adjustValue * 24 * 60 * 60 * 1000);
    const newEndDate = new Date(endDate.getTime() + adjustValue * 24 * 60 * 60 * 1000);

    const newFormattedWeek = `${formatDate(newStartDate)} - ${formatDate(newEndDate)}`;
    setCurrentWeek(newFormattedWeek);
    
  };
  const clustermapstyle = {
display:'none'
  };
  const buttonStyle = {
    height: '30px',
    width: '100px',
    marginTop:'5px',
    display:'none',
    important: true 
  };

  const isNextWeekButtonDisabled = 
    String(getFirstDayOfWeek(new Date(currentWeek.split(' - ')[0])).getDate()).padStart(2, '0') === 
    String(getFirstDayOfWeek(today).getDate()).padStart(2, '0');

  return (
    
    <div className="app-container">

      <header>
          <div className='biton' onClick={() => handleClick('previous')} disabled={isNextWeekButtonDisabled}>
            <ArrowBackIosNewIcon />
          </div>

          <h1>MOST ANTICIPATED EARNINGS RELEASES  <br />Week of {currentWeek.split(' - ')[0]} </h1>
          <div className='biton' onClick={() => handleClick()}>

            <ArrowForwardIosIcon /> 
          </div>
      </header>

      <CardListCompany ddday={new Date(currentWeek.split(' - ')[0])} />

      <footer>
        <a href="https://www.buymeacoffee.com/diamondx911" target="_blank">
          <img src="https://cdn.buymeacoffee.com/buttons/v2/default-green.png" alt="Buy Me A Coffee" style={buttonStyle} />
        </a>
        <a href="https://clustrmaps.com/site/1bz5b" title="Visit tracker"><img src="//clustrmaps.com/map_v2.png?cl=ffffff&w=a&t=n&d=bkhlDtOyAmkJ-cWWKvd9t_Aagjsq6-VddkliOb0Yd3U" style={clustermapstyle} /></a>
        <h1>Know someone who'd like this? Share the love!</h1>
        <h1>See something wonky? Got a cool idea? Hit me up on Reddit, <a href="https://www.reddit.com/user/tendiesforecast">u/tendiesforecast</a></h1>
      </footer>
    
    </div>
  );
}

export default App;

