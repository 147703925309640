import React, { useState, useEffect } from 'react';
import DaySectionMobile from './daysectionmobile';
import DaySectionDesktop from './daysectiondesktop';
import '../App.css';

const weekdays = ['MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY'];

function formatDate(date, separator = '/') {
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  const year = date.getFullYear();
  return `${month}${separator}${day}${separator}${year}`;
}

const CardListCompany = (props) => {
  // Get today's date
  const today = props.ddday;
  const isMobile = window.innerWidth <= 768;

  // Calculate the first day of the current week (assuming Sunday is the first day)
  const firstDay = new Date(today.setDate(today.getDate() - today.getDay() + 1));
  function lyouma() {
    const today = new Date();
    const month = today.getMonth() + 1; // January is 0!
    const day = today.getDate();
    const year = today.getFullYear();
    
  
    // Add leading zeros for single-digit month and day
    const formattedMonth = month < 10 ? `0${month}` : month;
    const formattedDay = day < 10 ? `0${day}` : day;
  
    return `${formattedMonth}/${formattedDay}/${year}`;
  }

  // Format the date strings for the current week
  return (
    <div key='list_companies' className='list_companies'>
      {weekdays.map((day, index) => {
        const currentDate = new Date(firstDay.getTime() + index * 24 * 60 * 60 * 1000);
        const formattedDate = formatDate(currentDate);
        const formattedjsonDate = formatDate(currentDate, '_');
        const apiUrl1 = `${process.env.REACT_APP_BACKEND_URL}${formattedjsonDate}`;
        return (
          isMobile ?
            <DaySectionMobile key={index} url={apiUrl1} day={formattedDate} weekday={day} lyouma ={lyouma()} /> :
            <DaySectionDesktop key={index} url={apiUrl1} day={formattedDate} weekday={day} lyouma ={lyouma()} />
        );
      })}
    </div>
  );
}

export default CardListCompany;
