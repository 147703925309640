import '../App.css';
import './card.css'
import React, { useState, useEffect } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Cardcompany from './Cardcompany';
import { useTheme } from '@mui/material/styles';


const Card = (props) => {
    const [data, setData] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [isEmpty, setIsEmpty] = useState(false);
    

    useEffect(() => {
        const fetchData = async () => {
          setIsLoading(true);
          try {
            const response = await fetch(props.url);
            const jsonData = await response.json();
            setData(jsonData);
            //console.log(jsonData);
            setIsEmpty(Array.isArray(jsonData) && jsonData.length === 0);
          } catch (error) {
            //console.error('Error fetching data:', error);
            setIsEmpty(true);
          } finally {
            setIsLoading(false);
          }
        };    
        fetchData();
      }, [props.date, props.url]);

      const renderCardData = (filterText) => {
        return isLoading ? (
            <p>Loading data...</p>
        ) : isEmpty ? (
            <p>No data</p>
        ) : (
            <>
                {data
                    ?.filter(company => company.image_alt_text === filterText)
                    .map((company, index) => (
                        <Cardcompany
                            key={index}
                            Symbol={company.ticker}
                            yf_link={company.yf_link}
                            Website={company.website}
                            name={company.company_name}
                            market_cap={company.market_cap}
                            pe={company.pe}
                        />
                    ))}
            </>
        );
    };

    return (
        <>
        <Accordion className={`day-section ${props.day === props.lyouma ? 'lyouma' : ''}`}>
        <AccordionSummary
          expandIcon={<ArrowDownwardIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          <Typography><div className='day-title'>{props.weekday} {props.day} </div></Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            <div >
                
                <div className='timing'>
                    <div key='before-market' className='before-after'>
                        BEFORE OPEN
                        {renderCardData('time-pre-market')}
                    </div>
                    <div key='after-market' className='before-after'>
                        AFTER CLOSE
                        {renderCardData('time-after-hours')}
                    </div>
                </div>
            </div>
          </Typography>
        </AccordionDetails>
      </Accordion>

        </>
    );
};

export default Card;
