import React from 'react'
import Button from '@mui/material/Button';


function Cardcompany(props) {
  const handleClick = () => {
    window.open(props.yf_link, '_blank');
  };
  return (
    <div key={props.index} className='cardcompany'>
        <div className='card'>
            <div className='img-box' onClick={handleClick}>
                <img src={props.Website} alt="" />
            </div>
            <div className='content'>
              <div className="ticker-container" onClick={handleClick}> 
                 <span className="ticker">{props.Symbol}</span>
              </div>
              <div className="marquee">
                <p>{props.name}</p>
              </div>
              <div className="marquee">
                <p>Market Cap: {props.market_cap}</p>
              </div>
              <div className="marquee">
                <p>PE Ratio: {props.pe}</p>
              </div>
              
            </div>
        </div>
    </div>
  )
}

export default Cardcompany
